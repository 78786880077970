/*Header Style Starts*/

header {
background: #1685CE;
}

.hdr-nav, .hdr-nav li {
margin-bottom: 0;
float: left;
}

.hdr-nav li {
padding: 0;
}

.hdr-nav li a {
color: #FFFFFF;
opacity: 0.6;
font-weight: 500;
padding: 20px 12px;
display: inline-block;
transition: all .13s linear;
-moz-transition: all .13s linear;
-webkit-transition: all .13s linear;
}

.hdr-nav li a:hover, .hdr-nav li a.active {
background: #0D76BB;
opacity: 1;
text-decoration: none;
}

header .profile-wrap {
float: right;
border-left: none;
}

header .nav-brand {
width: 110px;
padding-top: 2px;
}

header .profile-wrap .avatar {
height: 58px;
}

header .profile-wrap .avatar > span {
color: #fff;
}

header .profile-wrap:hover {
background: #0d76bb;
}

header .navbar-toggle {
margin: 12px -10px 0 0;
}

/*Header Style Ends*/

/*User Content Style Starts*/

.user-content {
min-height: calc(100vh - 80px);
padding-bottom: 50px;
overflow-x: hidden;
}

.title-sec {
border-bottom: 1px solid #E5E5E5;
padding: 20px 0;
margin-bottom: 35px;
position: relative;
}

.title-sec h3 {
color: #4F4F4F;
font-size: 18px;
font-weight: 600;
margin: 0;
}

.profile-widget {	
display: inline-block;
width: 565px	;
background: #FFFFFF;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.10);
-moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.10);
-webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.10);
border-radius: 4px;
padding-top: 45px;
position: relative;
z-index: 9;
margin-bottom: 30px;
}

.profile-widget:before {
content: "";
background: #FBFBFB;
position: absolute;
left: 0;
top: 0;
width: 100%;
height: 170px;
z-index: -1;
}

.profile-img-wrap {
position: relative;
width: 210px;
margin: 0 auto;
}

.change-pic {
position: absolute;
bottom: 12px;
right: 16px;
background: #fff;
box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
-moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
-webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
border-radius: 50%;
width: 40px;
height: 40px;
display: flex;
align-items: center;
justify-content: center;
opacity: 0;
visibility: hidden;
transition: all .13s linear;
-moz-transition: all .13s linear;
-webkit-transition: all .13s linear;
}

.profile-img-wrap:hover .change-pic {
visibility: visible;
opacity: 1;
}

.profile-img {
width: 210px;
height: 210px;
border-radius: 50%;
overflow: hidden;
border: 5px solid #fff;
box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
-moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
-webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
display: block;
margin-bottom: 20px;
}

.profile-name {
color: #707070;
font-size: 18px;
font-weight: 600;
display: table;
margin: 0 auto;
position: relative;
}

.profile-name span.edit {
opacity: 0;
visibility: hidden;
position: absolute;
padding-left: 8px;
right: auto;
top: 4px;
color: #2491D9;
font-size: 12px;
text-decoration: underline;
cursor: pointer;
transition: all .13s linear;
-moz-transition: all .13s linear;
-webkit-transition: all .13s linear;
}

.profile-name:hover span.edit {
opacity: 1;
visibility: visible;
}

.prof-cont-row {
border-bottom: 1px solid #EFEFEF;
padding: 25px 0;
}

.prof-cont-row .head {
color: #707070;
font-size: 14px;
font-weight: 600;
display: inline-block;
width: 100%;
margin-bottom: 3px;
}

.prof-cont-row span {
color: #707070;
font-size: 13px;
font-weight: 500;
}

.prof-cont-row .row {
display: flex;
align-items: center;
}

.profile-cont {
margin-top: 50px;
padding: 0 30px; 
}

.prof-cont-row .edit {
color: #2491D9;
font-size: 12px;
text-decoration: underline;
font-weight: 500;
}

.prof-cont-row .edit:hover {
text-decoration: none;
}

.show-pass {
display: inline-block;
}

.show-pass img.visible-icon {
margin-top: -8px;
}

.pass-sec.show .prof-pass, .pass-sec.show .show-pass .invisible-icon {
display: inline-block;
}

.pass-sec.show .pass-mask, .pass-sec.show .show-pass .visible-icon,
.prof-pass, .show-pass .invisible-icon {
display: none;
}

.assign-btn {
padding: 12px 25px;
font-size: 13px;
margin-bottom: 0;
}

.assign-btn img {
width: auto;
margin-right: 8px;
}

.user-content .pf-btm-wrap {
width: 100%;
background: #fff;
padding: 13px 0;
box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
-moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
-webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}

.user-content .custom-form {
margin-bottom: 25px;
}

.user-content a.back {
margin-top: 0;
}

a.role-link {
color: #464646;
font-weight: 600;
padding: 3px 0;
display: inline-block;
}

a.role-link + .remove {
cursor: pointer;
filter: grayscale(1);
-moz-filter: grayscale(1);
-webkit-filter: grayscale(1);
opacity: 0.4;
transition: all .13s linear;
-moz-transition: all .13s linear;
-webkit-transition: all .13s linear;
}

a.role-link + .remove:hover {
filter: grayscale(0);
-moz-filter: grayscale(0);
-webkit-filter: grayscale(0);
opacity: 1;
}

.custom-form.non-editable .form-control, .assign-btn {
pointer-events: none;
}

.custom-form.non-editable .role-link + .remove,
.custom-form.non-editable .pf-btm-wrap {
display: none;
}

.title-sec .pos-btn {
position: absolute;
right: 0;
top: 13px;
}

.btn.add-mem {
padding: 6px 15px;
display: flex;
align-items: center;
}

.btn.add-mem img {
margin-right: 8px;
}

.role-left .custom-checkbox {
padding-left: 20px;
position: absolute;
left: 0;
top: 24px;
}

.role-left .custom-checkbox .checkmark {
width: 20px;
height: 20px;
padding: 0;
background: #fff;
border: 1px solid #d6d6d6;
transition: none;
}

.role-left .custom-checkbox .checkmark:after {
left: 6px;
top: 1px;
}

.role-left .custom-checkbox input:checked ~ .checkmark:after {
background: transparent;
}

.user-content .role-label li {
padding-left: 25px;
position: relative;
}

.user-content .permission-enclose {
margin-bottom: 40px;
}

.member-table .badge {
font-weight: 500;
font-size: 12px !important;
color: #fff;
padding: 4px 7px;
border-radius: 4px;
}

.badge.blue {
background: #1685CE;
}

.badge.red {
background: #FF5151;
}

.badge.dark-yellow {
background: #F39C12;
}

.member-table .table td {
padding-top: 20px;
padding-bottom: 20px;
}

.info-widget {
width: 100%;
height: 100%;
display: inline-block;
border-radius: 4px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
-moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
-webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.info-widget .title {
background: #F8F8F8;
border-radius: 4px 4px 0 0;
font-weight: 600;
width: 100%;
display: inline-block;
padding: 15px 20px;
}

.info-widget .info-detail {
display: flex;
width: 100%;
padding: 20px;
align-items: center;
height: calc(100% - 48px);
}

.info-widget .icon-sec {
float: left;
width: 30%;
}

.info-widget .icon-cont {
float: left;
width: 70%;
color: #707070;
}

.info-widget .icon-cont .icon-title {
font-weight: 600;
font-size: 16px;
display: inline-block;
width: 100%;
margin-bottom: 10px;
}

.info-widget .icon-cont .badge {
text-transform: uppercase;
font-size: 10px;
}

.info-widget .icon-cont p {
font-weight: 500;
margin-bottom: 5px;
}

.info-row {
display: flex;
min-height: 210px;
margin-bottom: 30px;
}

.info-widget .title .text-link {
float: right;
color: #2491D9;
font-size: 12px;
text-decoration: underline;
margin-top: 2px;
}

.info-widget .title .text-link:hover {
text-decoration: none;
}

.info-widget .billing-table {
width: 100%;
}

.info-widget .billing-table thead {
border-bottom: 1px solid #ebebeb;
}

.info-widget .billing-table th {
font-weight: 600;
padding-bottom: 10px;
}

.info-widget .billing-table tr td {
font-weight: 500;
font-size: 13px;
padding: 6px 0;
}

.info-widget .billing-table tr td .view-pdf img, .list-table.billing-history tbody td .view-pdf img {
width: 16px;
opacity: 0.5;
transition: all .13s linear;
-moz-transition: all .13s linear;
-webkit-transition: all .13s linear;
}

.info-widget .billing-table tr td .view-pdf:hover img,
.list-table.billing-history tbody td .view-pdf:hover img {
opacity: 1;
}

.list-table.billing-history tbody td .view-pdf img {
width: 20px;
}

.info-detail.ext-pad {
padding-top: 35px;
padding-bottom: 35px;
}

.info-widget .subscriber-contact p {
color: #707070;
font-weight: 500;
display: inline-block;
width: 100%;
margin-bottom: 5px;
}

.info-widget .subscriber-contact p span:first-child {
font-weight: 600;
font-size: 13px;
color: #606060;
padding-right: 15px;
text-align: right;
width: 18%;
float: left;
}

.info-widget .subscriber-contact p span:last-child {
float: left;
width: 82%;
}

.list-table.billing-history thead th {
padding-left: 20px;
padding-right: 20px;
} 

.list-table.billing-history tbody td {
font-size: 13px;
font-weight: 500;
padding: 15px 20px;
}

.custom-dismissible {
padding: 20px 35px 20px 20px;
}

.custom-dismissible .alert-icon {
margin-right: 15px;
float: left;
}

.custom-dismissible.alert-danger {
border: 1px dashed #FFAAAA;
background: #FFE4E4;
font-weight: 500;
color: #F26262;
}

.custom-dismissible .close {
opacity: 0.4;
top: 3px;
}

.custom-dismissible.alert-danger .cont a {
color: #F26262;
text-decoration: underline;
}

.custom-dismissible.alert-danger .cont a:hover {
text-decoration: none;
}

/*User Content Style Ends*/


/* Reponsive Style Starts */

@media screen and (max-width: 991px) {

.info-row {
display: inline-block;
margin-bottom: 0;
}

.info-widget {
margin-bottom: 20px;
}

}

@media screen and (max-width: 767px) {

.profile-widget {
width: 100%;
}

.prof-cont-row {
padding: 15px 0;
}

.prof-cont-row .row {
flex-flow: column;
}

.prof-cont-row .row .text-right {
text-align: left !important;
margin-top: 5px;
}

.profile-img-wrap {
width: 180px;
}

.profile-img {
width: 180px;
height: 180px;
}

header .navbar-toggle .icon-bar {
background: #fff;
}

.res-nav-sec {
position: absolute;
top: 57px;
z-index: 999;
width: 100%;
}

.res-nav-sec .navbar-collapse {
padding: 0;
}

.hdr-nav {
margin-left: 0;
}

.hdr-nav li, .hdr-nav li a {
width: 100%;
}

.hdr-nav li a {
padding: 12px;
background: #1685CE;
opacity: 1;
border-bottom: 1px solid #65abda;
}

.title-sec {
padding: 15px 0;
margin-bottom: 25px; 
}

.title-sec h3 {
font-size: 16px;
}

.title-sec .pos-btn {
top: 8px;
}

.info-widget .info-detail {
flex-flow: column;
}

.info-widget .icon-sec, .info-widget .icon-cont {
width: 100%;
text-align: center;
}

.info-widget .icon-sec {
margin-bottom: 20px;
}

.info-widget .info-detail .table-responsive {
margin-bottom: 0;
}

.info-widget .subscriber-contact p span:first-child {
width: 100%;
text-align: left	
}

}

/* Reponsive Style Ends */